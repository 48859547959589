<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-img max-height="240" contain class="mgt-12px"
              :src="require('@/assets/image/workshop18-1.jpg')"
            />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="white-text heading mgt-12px">
                {{ title }} <br/>
                <div><span>({{ date }})</span></div>
                <br/>
                {{price}}
                <!-- Earlybird 3,900 บาท (ถึง 6 มี.ค. 2563) <br>
                <div class="sm-text">
                  <s>จากราคาเต็ม {{ price }}</s>
                </div> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                v-if="isSale"
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow " >
              <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
                <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                  <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                  <!-- <v-img
                    :src="`${salebox.imgicon}`"
                    width="30px">
                  </v-img>
                </v-col>
                <v-col cols="10" sm="10" md="4">
                  <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                </v-col> -->
              </v-row>
              <br/>
              <v-row dense no-gutters>
                <v-col cols="2">
                  <br/>
                  <v-row justify="center">
                    <v-img
                      :src="require('@/assets/image/icon-14.svg')"
                      max-width="30px">
                    </v-img>
                  </v-row>
                    <!-- <v-icon>fas fa-chalkboard-teacher</v-icon> -->
                </v-col>
                <v-col >
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px pt-0">
              <div class="pd-24px text-content">
                เพราะยุคนี้คือยุคของข้อมูลจำนวนมหาศาลหรือที่เรียกกว่า BIG DATA
                อย่างไรก็ตาม ข้อมูลจำนวนมากมายมหาศาลนั้นยากมากเกินความเข้าใจ
                ทำให้เราไม่สามารถตรวจสอบได้เลยว่าอันไหนมีประโยชน์ที่ควรนำมาใช้ต่อยอดกับการทำงาน
                DATA Visualization จึงเป็นทางออกให้กับผู้ที่กำลังมีปัญหาเหล่านี้<br/><br/>

                แต่ก่อนเราอาจใช้เพียงแผนภูมิ กราฟแท่ง ซึ่งอาจจะไม่เพียงต่อ
                หากข้อมูลที่คุณมีนั้นมากเกินไป
                <br/><br/>

                <strong>เนื้อหาที่คุณจะได้รับจาก DATA Visualization มีดังนี้</strong><br/>
                1) หลักการทํา Visualization วิธีการออกแบบการนำเสนอเบื้องต้น<br/>
                2) หลักการเล่าเรื่อง (Storytelling) อย่างน่าสนใจ การใช้กราฟชนิดต่าง ๆ
                ในการเล่าเรื่อง<br/>
                3) การตั้งคําถามที่น่าสนใจจากข้อมูล การทํา visualization
                ผ่านไลบรารีภาษาโปรแกรม Python ได้แก่ Pandas และ Seaborn
                การวิเคราะห์ผลลัพธ์ที่ได้เพื่อนําไปช่วยตัดสินใจทางธุรกิจ<br/>
                4) ผู้เรียนจะได้ลงมือทําโจทย์จากชุดข้อมูลที่กําหนดและนําเสนอผลงาน
                เล่าเรื่องที่น่าสนใจจากข้อมูล โดยมีอาจารย์คอยแนะนำวิธีการพัฒนาผลงานให้ดียิ่งขึ้น
                <br/><br/>

                <strong>Workshop นี้เหมาะกับใคร</strong><br/><br/>

                พนักงานองค์กร ผู้บริหาร บุคคลทั่วไปที่สนใจ หรือผู้ที่ต้องการนำเสนอข้อมูลให้น่าสนใจ
                โดยผู้เรียนควรมีพื้นฐานการเขียนภาษาโปรแกรม Python ในระดับเบื้องต้น

                <br/><br/>

                <strong>ทำไมคุณถึงต้องเรียน DATA Visualization</strong><br/><br/>

                <!-- eslint-disable-next-line max-len -->
                คุณสามารถนำองค์ความรู้ในการนําเสนอข้อมูลให้เห็นภาพไปประยุกต์ใช้กับข้อมูลภายในองค์กรของตนเองหรือแม้แต่ข้อมูลที่ตัวเองมีอยู่
                การแปลงข้อมูลจำนวนมากและซับซ้อนให้กลายเป็นภาพที่เข้าใจได้ง่ายผ่านการใช้ภาษา
                <!-- eslint-disable-next-line max-len -->
                Python จนสามารถทำให้ค้นพบจุดที่น่าสนใจและกลายเป็นองค์ความรู้ใหม่ที่สามารถช่วยขับเคลื่อนองค์กรได้
                <br/><br/>

                <strong>“ สิ่งสำคัญเหนือสิ่งอื่นใดคือ หากมีข้อมูลมากมาย
                  แต่นำมาใช้ประโยชน์สูงสุดไม่ได้ มันก็กลายเป็นแค่
                  ไฟล์ติดคอมพ์ไฟล์นึงเท่านั้น ”</strong>
                  <br/><br/>

                <strong>สอนโดย</strong><br/>
                ผศ.ดร.สุกรี สินธุภิญโญ<br/>
                ภาควิชาวิศวกรรมคอมพิวเตอร์
                คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย<br/><br/>

                โดยผู้เข้าร่วมเวิร์กชอปทุกท่านจะได้รับประกาศนียบัตรเชิงปฎิบัติการจาก
                Achieve<br/><br/>

                คุณจะได้ทั้งหมดนี้ในราคาเพียง 5,900 บาทเท่านั้น<br/><br/>

                <strong>วัน เวลา</strong><br/>
                - วันที่: 21 มีนาคม 2563<br/>
                - เวลา: 08:00 - 17:30 น.<br/><br/>

                <strong>สถานที่</strong><br/>
                - ทีมงานจะแจ้งให้ทราบอีกครั้ง<br/><br/>

                <strong>หมายเหตุ</strong><br/>
                - ผู้เรียนต้องนำโน้ตบุ๊กส่วนตัวมาเอง<br/><br/>


                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">CHULA MOOC Achieve</a><br/><br/>
              </div>
              <!-- <v-row>
                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-17-image-1.jpg')"
                  ></v-img>
                </v-col>

                 <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-17-image-2.jpg')"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-17-image-3.jpg')"
                  ></v-img>
                </v-col>

                 <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-17-image-4.jpg')"
                  ></v-img>
                </v-col>

              </v-row> -->
            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    v-if="isSale"
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn>
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <v-img
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                  <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                  <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                    <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                    <!-- <v-img
                      :src="`${salebox.imgicon}`"
                      width="30px">
                    </v-img>
                  </v-col>
                  <v-col cols="10" sm="10" md="4">
                    <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                  </v-col> -->
                </v-row>
                <br/>
                <v-row dense no-gutters >
                  <v-col cols="2">
                    <br/>
                    <v-row justify="center">
                      <v-img
                        :src="require('@/assets/image/icon-14.svg')"
                        max-width="30px">
                      </v-img>
                    </v-row>
                      <!-- <v-icon>fas fa-chalkboard-teacher</v-icon> -->
                  </v-col>
                  <v-col class="text-content">
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'workshopDetail',
  props: ['workshopId'],
  data() {
    return {
      card_text: '',
      sku: 'ws0180018',
      workshop: [
        { title: 'Data Visualization Workshop รุ่น 2', date: '21/03/2563' },
      ],
      title: 'Data Visualization Workshop รุ่น 2',
      date: '21/03/2563',
      price: '5,900 บาท',
      salebox: [
        {
          title: 'อบรมเชิงปฏิบัติการ (workshop)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'วันเสาร์ที่ 21 มีนาคม 2563', icon: 'fas fa-calendar-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '08:00 - 17:30 น.', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'ทีมงานจะแจ้งให้ทราบอีกครั้ง', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-11.svg'), id: 4,
        },
        {
          title: 'ระดับกลาง ขั้นต้น (Lower Intermediate level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          title: 'Achieve+ Member รับส่วนลด 25%', link: '/member', icon: 'fas fa-star', imgicon: require('@/assets/image/icon-13.svg'), id: 6,
        },
      ],
      isSale: new Date('2020-03-18T10:00:00.000Z') > new Date(),
      preInstructor: 'สอนโดย',
      instructor: 'ผศ.ดร.สุกรี สินธุภิญโญ',
      department: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
      faculty: 'คณะวิทยาศาสตร์',
      university: 'จุฬาลงกรณ์มหาวิทยาลัย',
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-bg{
  background-color: #001544;
  /* margin-left: -600px;
  margin-right: -600px; */
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.text-content{
  font-size: 0.875rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
